import BaseSkeleton from '@/components/base/baseSkeleton';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { getNewsDetail } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useLocation } from 'umi';

/**
 * 新闻详情 这个是标准的解析 Markdown的页面
 */
export default (props: { scrollElement?: HTMLElement }) => {
  const { state } = useLocation();
  //@ts-ignore
  const id = state?.id;

  const { data, loading } = useReq(() => getNewsDetail({ id }), {
    ready: id,
  }) as any;
  return (
    <div>
      <BaseTopNav></BaseTopNav>
      <div className="p-4 ">
        <BaseSkeleton isLoaded={!data || loading}>
          <div className="flex flex-col justify-between h-[130px] px-4">
            <div className=" h-2 bg-backgroundAuxiliaryColor rounded-md"></div>
            <div className="h-2 bg-backgroundAuxiliaryColor rounded-md mr-10"></div>
            <div className=" h-2 bg-backgroundAuxiliaryColor rounded-md"></div>
            <div className="h-2 bg-backgroundAuxiliaryColor rounded-md mr-6"></div>
            <div className="h-2 bg-backgroundAuxiliaryColor rounded-md mr-4"></div>
            <div className="h-2 bg-backgroundAuxiliaryColor rounded-md mr-8"></div>
          </div>
        </BaseSkeleton>
        {data && !loading && (
          <>
            <div className="text-2xl text-backContrastColor text-center mb-4">
              {data?.title}
            </div>
            <FeaturesRenderText data={data} />
          </>
        )}
      </div>
    </div>
  );
};
